import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import InnerPage from "../../components/innerPage";
// import parse from "html-react-parser"
// import coursesTeaching from "../../../content/assets/images/courses-teaching-cropped.png"
import coursesTeaching from "../../../content/assets/images/courses-teaching-cropped.jpg"

const Courses = () => {
    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();

    const breadcrumbs = [
        {
            title: t('courses'),
        }
    ]

    /**
     * Object Array for generating the "Courses" section
     * @type {[{featured: boolean, title: string, descriptions: string[], url: string}]}
     */
    const courseBrief = [
        {
            title: t('tutoringClass'),
            descriptions: [
                t('privateTutor'),
                t('chineseClass'),
                t('englishClass'),
                t('mathClass'),
                t('phonicsClass'),
                t('groupEngTutor'),
            ],
            url: "/courses/tutoring-classes",
            featured: false,
        },
        {
            title: t('ascp'),
            descriptions: [
                t('normalAscp'),
                t('senAscp'),
            ],
            url: "/courses/ascp",
            featured: true,
        },
        {
            title: t('interestClasses'),
            descriptions: [
                t('taekwondo'),
                t('goChess'),
                t('childrenArtClass'),
                t('childrenSkatingClass'),
            ],
            url: "/courses/interest-classes",
            featured: false,
        }
    ]

    useEffect(() => {

    }, [])

    return (
        <Layout>
            <SEO title={t('courses')}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('courses')}
            >
                <section id="courses" className={"pricing text-white py-0"}>
                    <div className="container" data-aos="fade-up" >

                        <div className={"row mb-4 mb-md-5"}>
                            <img src={coursesTeaching} className={"img-fluid"} alt={"Teaching Courses"} />
                        </div>

                        <div className={"row"} >
                            {courseBrief.map((x, i) => {
                                return (
                                    <div className="col-lg-4 col-md-6 my-1 my-md-0" key={i}>
                                        <div className={`box ${x.featured ? "featured" : ""}`} data-aos="fade-up" data-aos-delay="100">
                                            <h3>{x.title}</h3>
                                            {/*<h4><sup>$</sup>0<span> / month</span></h4>*/}
                                            <ul>
                                                {x.descriptions.map((k, ii) => {
                                                    return (
                                                        <li key={ii}>
                                                            {k}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <div className="btn-wrap">
                                                <Link to={x.url} className="btn-buy">{t('learnMore')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>


                    </div>
                </section>
            </InnerPage>
        </Layout>
    );
};

export default Courses;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;